export function WebsiteStatistik(): JSX.Element {
  return (
    <div>
      <div
        style={{ marginTop: "20px" }}
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://grafanawebsitestatistic.northeurope.cloudapp.azure.com:3000/d-solo/SUiW_prVz/quelle-augsburg-last30days?orgId=1&panelId=2" width="800" height="400" frameborder="0"></iframe>`,
        }}
      ></div>
      <div
        style={{ marginTop: "20px" }}
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://grafanawebsitestatistic.northeurope.cloudapp.azure.com:3000/d-solo/WfDe_trVz/quelle-augsburg-lastyear?orgId=1&panelId=2" width="800" height="400" frameborder="0"></iframe>`,
        }}
      ></div>
    </div>
  );
}
