import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Footer(): JSX.Element {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col py-2">
              <h3 id="contact" style={{marginBottom: "-5px"}}>Kontakt</h3>
              <br />
              <a
                className="mailLink"
                href="mailto:info@qualleaugsburg.de"
              >info@qualleaugsburg.de</a>
            </div>
            <div className="col py-2">
              <h3 id="insta" style={{marginRight: "-5em", marginLeft: "13.2em" }}>Social Media</h3>
              <a
                href="https://www.instagram.com/qualle_augsburg/"
                target="_blank"
                className="me-2"
                rel="noopener noreferrer"
              >
                <i className="bi bi-instagram footerIcons"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
