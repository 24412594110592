import "./Image.css"

type BaseImageProps = {
    src: string
    alt?: string,
}

type ZoomableImageProps = BaseImageProps & {
  sizes?: string,
}

export function ZoomableImage(props: ZoomableImageProps): JSX.Element {
    return (
        <img loading="lazy" src={props.src} alt={props.alt} className="zoomable-image" sizes={props.sizes}/>
    );
  }

type FloatLeftImageProps = BaseImageProps & {
    maxWidth?: string
}

export function FloatLeftImage(props: FloatLeftImageProps): JSX.Element {
    return (
        <img
          loading="lazy"
          src={props.src}
          alt={props.alt}
          style={{ maxWidth: props.maxWidth ?? "700px" }}
          className="float-left FloatLeftImage"
        />
    )
}
