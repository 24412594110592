import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Kalender() {
  return (
    <>
      <br></br>
      <h1 id="Quallender">Der Quallender</h1>
      <br></br>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
        }}
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://teamup.com/ksfwf6tgdxeu3pjyxu?view=m&showHeader=0&showLogo=1&showProfileAndInfo=0&showSidepanel=1&disableSidepanel=1&showTitle=1&showViewSelector=1&showMenu=0&weekStartDay=mo&showAgendaHeader=1&showAgendaDetails=1&showYearViewHeader=1" style="width: 100%; height: 800px" loading="lazy" frameborder="0"></iframe>`,
        }}
      ></div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}
