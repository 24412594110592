import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FloatLeftImage } from "../components/Image";


export function Projekte() {
  return (
    <>
      <br></br>
      <b><h1 id="aktiveprojekte" className="white-text">Aktive Projekte</h1></b>
      <br></br>

      <i><h2 id="projektequartierszentrum">Projekte rund ums Quartierszentrum</h2></i>
      <br></br>
      <h3 id="nachbarschaftscafe">Nachbarschaftscafé</h3>
      <br></br>
      <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/Nachbarschaftscafe-min.png" alt="Food1" />
          </div>
        </div>
      <p>
        Das Nachbarschaftscafé ist ein Treffpunkt im Quartierszentrum Rechts-der-Wertach. Hier kannst du Menschen aus dem Quartier und drumherum begegnen, dich  mit ihnen austauschen und einfach eine gute Zeit haben! Auf freiwilliger Spendenbasis gibt es Kaffee, Tee, Wasser und ab und zu auch ein paar Snacks oder einen selbstgebackenen Kuchen. Komm vorbei und lerne das Café und das Quartiersprojekt kennen. Gemeinsam für eine lebendige und gemeinschaftliche Nachbarschaft! <br></br>
        <br></br>
        Aktuell öffnet das Nachbarschaftscafé <b>Donnerstags von 11:00 bis 15:00</b> und <b>Sonntags von 16:00 bis 20:00</b> Uhr!
        Wann das Nachbarschaftscafé geöffnet ist, erfährst du ansonsten auch über den <a
          href="https://www.qualle-augsburg.de/kalender"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Kalender
        </a>.
        <br></br>
      </p>
      <br></br>
      <hr />

      <h3 id="Sprachcafé">Sprachcafé</h3>
      <br></br>
      <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/SC1.png" alt="Food1" />
          </div>
        </div>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/SC2.png" alt="Food1" />
          </div>
        </div>
      <p>
       Ab September wird es ein weiteres Sprachcafé in Augsburg geben!<br></br>
       <br></br>
       Wir sind froh ankündigen zu können, dass wir - in Kooperation mit Tür an Tür e.V. - das fünfte wöchentlich stattfindende Sprachcafé (auf deutsch) initiieren. Jeden Donnerstag laden wir Menschen, die ihr deutsch verbessern wollen, ein, zwischen 15 und 17 Uhr ins Quartierszentrum Rechts-der-Wertach zu kommen.<br></br>
       <br></br>
       Wie ihr in einem vorherigen Beitrag von uns nachlesen könnt:<br></br>
       Die Bundesregierung (speziell das Innenministerium) wird die Ausgaben für Integrations- und Sprachkurse um die Hälfte halbieren - von 1,1 Mrd. auf 500 Mio. Dieses Geld soll umgelegt werden auf die "innere Sicherheit", also für die Polizei. Integration funktioniert nicht durch Kriminalisierung von migrantisch gelesenen Menschen!<br></br>
       <br></br>
       Umso wichtiger ist es jetzt, das ehrenamtliches Angebot für Integration auszuweiten. Also: Unterstütze und dabei!<br></br>
       Hier sind die Möglichkeiten wie du uns unterstützen kannst:<br></br>
       <br></br>
       1. Als Sprachhelfer*in unterstützt du im Sprachcafé diejenigen Menschen, die ihr deutsch verbessern möchten. Dafür brauchst du keine pädagogische Ausbildungen oder andere Qualifikationen - nur Spaß und Freude dabei, anderen etwas beizubringen.<br></br>
       <br></br>
       2. Als Multiplikator*in der Idee informierst du deine Freunde und Bekannten über das Projekt. Teile diesen Beitrag und hilf mit, Sprachhelfer*innen für das Sprachcafé zu begeistern.<br></br>
       <br></br>
       3. Komm zum Initiativtreffen am 29. August um 15 Uhr im Quartierszentrum und unterstütze uns dabei, die letzten Schritte für einen guten Start des Projekts zu gehen.<br></br>
       <br></br>
       Wir freuen uns auf dich!<br></br>
       -----<br></br>
       <br></br>
       We are pleased to announce that - in cooperation with Tür an Tür e.V. - we are initiating the fifth weekly language café (in German). Every Thursday we invite people who want to improve their German to come to the Quartierszentrum Rechts-der-Wertach between 3 and 5 pm.<br></br>
       <br></br>
       As you can read in our previous post:<br></br>
       The German government (specifically the Ministry of the Interior) will halve spending on integration and language courses - from 1.1 billion to 500 million. This money is to be reallocated to ‘internal security’, i.e. for the police. Integration does not work by criminalising migrant people!<br></br>
       <br></br>
       This makes it all the more important now to expand the range of voluntary integration programmes. So: Support us and join us! Here are the ways you can support us:<br></br>
       <br></br>
       1. as a language helper, you can support people in the language café who want to improve their German. You don't need any pedagogical training or other qualifications - just fun and enjoy teaching others.<br></br>
       <br></br>
       2. as a multiplier of the idea, you inform your friends and acquaintances about the project. Share this article and help to inspire language helpers for the language café.<br></br>
       <br></br>
       3. come to the initiative meeting on 29 August at 3 pm in the neighbourhood centre and help us take the final steps to get the project off to a good start.<br></br>
       <br></br>
       We look forward to seeing you!
      </p>
      <br></br>
      <hr />

      <i>
        <h2 id="gartenprojekte">Gartenprojekte im Quartier</h2>
      </i>
      <br></br>
      <h3 id="incredibleedible">Incredible Edible</h3>
      <br></br>
      <p>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/planze.JPG" alt="Food1" />
          </div>
        </div>
        Rechts-der-Wertach wird Teil des{" "}
        <a
          href="https://www.incredibleedible.org.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Incredible Edible Netzwerks
        </a>
        .
        Auf dem Grünstreifen vor dem Quartierszentrum sowie bei
        unserem Nachbarschaftscafé sprießt es aus bepflanzten Hochbeeten. Alles,
        was wir anpflanzen, ist essbar und gleichzeitig auch positiv für die
        städtische Biodiversität. Die Hochbeete schaffen damit Räume, in
        denen Menschen zusammenkommen, heimische Esspflanzen kennenlernen und
        gemeinschaftlich von ihnen profitieren können.<br></br>
        Vorbild des Projekts ist das sogenannte{" "}
        <i>Incredible Edible Movement</i> („Unglaublich-essbar-Bewegung“), das
        vor etwa 10 Jahren in einer Kleinstadt in Großbritannien entstanden und
        inzwischen auf der ganzen Welt verbreitet ist.<br></br>
        <br></br>
        Believe in the power of small actions! Wir freuen uns, wenn ihr uns
        unterstützt. Wenn Du mitmachen möchtest und selbst in unserem Quartier
        Essbares anpflanzen willst, melde dich bei uns: info@qualleaugsburg.de
        <br></br>
      </p>
      <br></br>
      <hr />

      <h3 id="Quartiersgarten Austrasse">Quartiersgarten Austrasse</h3>
      <br></br>
      <p>
        Alle Infos zum Stadtteilgarten am Ende der Austraße findest du <a
          href="https://www.rechts-der-wertach.de/stadtteilgarten/"
          target="_blank"
          rel="noopener noreferrer"
        >
        hier
        </a>
        .<br></br>
        <br></br>
      </p>
      <hr />

      <i><h2 id="essensprojekte">Essensprojekte im Quartier</h2></i>
      <br></br>
      <h3 id="küchefüralle">Kueche fuer Alle</h3>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "700px",
            width: "100%",
            marginBottom: "1em",
            marginTop: "-1em",
          }}
          src="img/KÜCHE FÜR ALLE.png"
          alt="Müll25.11."
          className="FloatLeftImage"
        />
      </div>
      <br></br>
      <p>
        Im 14-tägigen Rhythmus findet bei uns im Quartierszentrum aktuell die
        gemütliche Suppenküche für Alle statt. Aus veganen, geretteten
        Lebensmitteln kochen wir eine Suppe - wie immer auf Spendenbasis. Die
        nächsten Termine sind:<br></br>
        <br></br>
        13.07./27.07./10.08./24.08./07.09.<br></br>
        <br></br>
        Wir freuen uns auf dich!
      </p>
      <br></br>
      <hr />

      <h3 id="foodcare">Food Care</h3>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "700px",
            width: "100%",
            marginBottom: "1em",
            marginTop: "-1em",
          }}
          src="img/foodcare.jpg"
          alt="Müll25.11."
          className="FloatLeftImage"
        />
      </div>
      <br></br>
      <p>
        Um den Foodverteiler zu betreuen, gibt es ein Hygienekonzept: Mindestens
        einmal in der Woche muss der Verteiler gründlich geputzt werden. Täglich
        kontrollieren Engagierte und sortieren nicht mehr essbare Lebensmittel
        aus.<br></br>
        <br></br>Wenn du mithelfen möchtest, komm in diese{" "}
        <a
          href="https://t.me/+zJ2v9Fr7lDA5NjUy"
          rel="noreferrer"
          target="_blank"
        >
          Telegramgruppe
        </a>
        <br></br>
        oder schreibe eine Mail an info@qualleaugsburg.de<br></br>
        <br></br>
        Auch wenn du Essensretter*in werden möchtest, bist du hier genau
        richtig.<br></br>
        <br></br>
        Nur gemeinsam schaffen wir es, dass dieses tolle Projekt weiterhin
        bestehen bleibt. Also komm ins Team Food Care!<br></br>
        <br></br>
      </p>
      <br></br>
      <hr />


      <b><h1 id="etablierteprojekte" className="white-text">Etablierte Projekte</h1></b>
      <br></br>

      <h3 id="foodverteiler">Foodverteiler</h3>
      <br></br>
      <br></br>
      <p>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/Update 1.JPG" alt="Food1" />
          </div>
          <div className="image-container">
            <FloatLeftImage src="img/Update 2.JPG" alt="Kippen4" />
          </div>
          <div className="image-container">
            <FloatLeftImage src="img/Update 3.JPG" alt="Kippen3" />
          </div>
        </div>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/TS1.jpg" alt="Kippen5" />
          </div>
          <div className="image-container">
            <FloatLeftImage src="img/Futterquelle1.jpeg" alt="Kippen2" />
          </div>
        </div>
        <br></br>
        <p>
          <br></br>
          Für die Futterquelle ist Folgendes wichtig:<br></br>
          Der Foodverteiler ist ein Gemeinschaftsprojekt und kann nur
          funktionieren, wenn alle mitmachen!<br></br>
          Achte deshalb bitte auf diese Regeln:<br></br>
          <br></br>
          <ul>
            <li>
              Wenn du etwas aus dem Verteiler nimmst, schick immer ein Foto des
              aktuellen Stands in die WhatsApp-Gruppe
            </li>
            <li>
              Wenn du etwas siehst, das schlecht ist oder nicht in den Verteiler
              gehört, nimm es heraus
            </li>
            <li>Hinterlasse den Verteiler immer sauber und ordentlich</li>
            <li>
              Achte darauf, alles immer gut zu verschließen. Denn der Ort ist
              nahe des Senkelbachs und somit besteht erhöhter Rattenverkehr
            </li>
          </ul>
          <br></br>
          Hier kommst du in die WhatsApp Gruppe, in der der aktuelle Stand
          kommuniziert wird:{" "}
          <a
            href="https://chat.whatsapp.com/JQDpdFCYgNhDjjRLbHNrM7"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://chat.whatsapp.com/JQDpdFCYgNhDjjRLbHNrM7
          </a>
          <br></br>
          <br></br>
        </p>
        <br></br>
      </p>
      <hr />

      <h3 id="kippenprojekt">Kippenfreie Wertachstrasse</h3>
      <br></br>
      <p>
        Zigarettenstummel haben gravierende Auswirkungen auf die Umwelt. Sie
        gehören fast selbstverständlich zum Anblick in unserer Umgebung. Doch
        was für Viele eine Kleinigkeit ist, summiert sich im Ganzen auf die
        unglaubliche Zahl von 4,5 Billionen jährlich weggeworfener Kippen. Bei
        der Belastung der Umwelt durch Abfall spielen Zigarettenstummel damit
        zahlenmäßig die größte Rolle weltweit. Beide Bestandteile eines
        Zigarettenstummels sind umweltschädlich: der Filter und der Tabakrest.
        So kann eine einzige Kippe mit ihrem Mix aus Giftstoffen zwischen 40 und
        60 Liter sauberes Grundwasser verunreinigen und das Pflanzenwachstum
        negativ beeinflussen (
        <a
          href="https://naturschutzbund.at/umweltthemen/articles/kleine-ursache-grosse-wirkung-zigarettenstummel-in-der-umwelt.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Quelle
        </a>
        ).<br></br>
        <br></br>
      </p>
      <div className="image-gallery">
        <div className="image-container">
          <img
            src="img/aschenbecher1.jpeg"
            alt="Kippen1"
            className="FloatLeftImage"
          />
        </div>
        <div className="image-container">
          <img
            src="img/RECYCLING Sticker.png"
            alt="Kippen4"
            className="FloatLeftImage"
          />
        </div>
        <div className="image-container">
          <img
            src="img/Müllsammelaktion 25.11..jpeg"
            alt="Kippen3"
            className="FloatLeftImage"
          />
        </div>
      </div>
      <div className="image-gallery">
        <div className="image-container">
          <img
            src="img/foto az helga.png"
            alt="Kippen5"
            className="FloatLeftImage"
          />
        </div>
        <div className="image-container">
          <img
            src="img/aschenbecher2.jpeg"
            alt="Kippen2"
            className="FloatLeftImage"
          />
        </div>
      </div>
      <br></br>
      <p>
        Vielen Menschen sind diese Auswirkungen nicht bewusst und sie werfen
        ihre Zigaretten ungeachtet auf den Boden. Diese Problematik ist uns auch
        in der Wertachstraße aufgefallen.<br></br>
        Wir wollen darauf aufmerksam machen und Bewusstsein dafür schaffen, was
        für Auswirkungen das kurze Wegschnipsen von Zigaretten haben kann. Dafür
        installierten wir 6 schicke Aschenbecher-Kästen in der Wertachstraße und
        leeren diese regelmäßig. Weitere kleinere Kästen sind in Planung. Um
        eine umweltschädliche Verbrennung zu verhindern, suchen wir noch nach
        Möglichkeiten, die Zigarettenstummel zu recyclen. Zudem wollen wir die
        Menschen mit Plakaten, Sammelaktionen und Kunstinstallationen für die
        Thematik sensibilisieren. Hoffentlich wird die Wertachstraße dadurch
        bald kippenstummelfrei!<br></br>
        <br></br>
        Wenn du uns bei dem Projekt unterstützen möchtest, melde dich gerne bei
        uns oder komm in unserem wöchentlichen Plenum vorbei!<br></br>
      </p>
      <br></br>
      <hr />

      <h3 id="tauschschrank">Tauschschrank</h3>
      <br></br>
      <p>
        Der Tauschschrank ist im Grunde selbsterklärend: Gib ab, was du nicht
        mehr brauchen kannst und nimm mit, was dir Freude bereitet. So können
        wir gemeinsam unseren Konsum reduzieren und alten Dingen ein neues Leben
        geben.<br></br>
      </p>
      <br></br>
      <div className="image-container">
        <img src="img/TS1.jpg" alt="Kippen5" className="FloatLeftImage" />
      </div>
      <br></br>
      <hr />

      <b><h1 id="projekte-in-2023" className="white-text">Projekte in 2023</h1></b>
      <br></br>
      <h3 id="oase" >Oase - Ort der Begegnung</h3>
      <br></br>
      <div className="image-gallery">
        <div className="image-container">
          <img
            style={{
              maxWidth: "700px",
              height: "auto",
              marginBottom: "0.5em",
              float: "right",
            }}
            src="img/oase5.jpeg"
            alt="Food1"
            className="FloatLeftImage"
          />
        </div>
      </div>
      <p>
        An der Stelle, wo die Lindenstraße die Mittelstraße kreuzt, haben wir
        eine Fläche von etwa zwei Parkplätzen gestaltet. Hier entsteht ein Ort
        der Begegnung. Ein Ort, um sich auszutauschen, die Nachbarschaft
        kennenzulernen und die Gemeinschaft in unserem Quartier zu stärken. Die
        Oase soll Menschen dazu inspirieren, selbst aktiv zu werden, um
        gemeinsam unsere Umgebung zu gestalten. Wir möchten verdeutlichen, dass
        der urbane Raum den Bedürfnissen und Wünschen der Menschen dienen kann
        und soll. Die Oase bietet die Möglichkeit, sich innerhalb des Viertels
        zu begegnen und zu vernetzen. Denn gesellschaftlicher Zusammenhalt,
        Austausch und Teilhabe sind unglaublich wichtig, um die
        Herausforderungen der heutigen Zeit zu bewältigen. Nur so kommen wir
        einer gestärkten Gemeinschaft näher und können gemeinsam die Zukunft
        unseres Viertels aktiv gestalten.<br></br>
        <br></br>
        <i>
          Wir sind in eigene winterfeste Räumlichkeiten umgezogen - Das
          Quartierszentrum in der Wiesenstraße
        </i>
        <br></br>
        <br></br>
        Nachdem die Oase nun ganze 5 Monate als Ort der Begegnung diente, haben
        wir sie Ende Oktober abgebaut. Im nächsten Frühjahr wird es aber wieder
        einen neuen Ort geben, an dem sich Menschen niederlassen können -
        zwischen Betonwüste und Blechlawinen!
        <br></br>
        <br></br>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/Ende der Oase.jpg" alt="endeoase" />
          </div>
        </div>
      </p>
      <br></br>
      <hr />

      <h3 id="quallenfest" >Quallenfest</h3>
      <br></br>
      <p>
        <b>Straßenfest 14. & 15. Oktober 2023</b><br></br>
        <br></br>
        Was für ein tolles Wochenende!<br></br>
        Am Samstag fielen noch einige Tropfen vom Himmel - es war nicht viel los. Am Sonntag hingegen hatten wir eine tolle Zeit. Gegen 14 Uhr kam allmählich die Sonne raus und schenkte uns einen guten Vibe. Menschen aus dem Quartier kamen vorbei und lernten sich kennen. Hier geht was in Rechts der Wertach. Wir freuen uns schon auf das nächste Mal!<br></br>
        <br></br>
        <div className="d-flex flex-column">
        <video
          style={{ maxWidth: "700px", width: "100%", marginBottom: "0em" }}
          className="float-left"
          controls
        >
          <source
            src="Video Björn.mp4"
            type="video/mp4"
          />
        </video>
      </div>
        <br></br>
        <br></br>
        Danke an alle Menschen und Gruppen, die das Fest unterstützt haben:<br></br>
        <ul>
          <li>Sozialer Mampf</li>
          <li>Pa*radieschen Hausprojekt</li>
          <li>Sherlo e.V.</li>
          <li>Augsburg für Alle</li>
          <li>Kitsch und Krawall</li>
          <li>Trio M.3</li>
          <li>DJ vanderFelden</li>
          <li>Izy</li>
          <li>Björn</li>
          <li>Vintage & Kraetze</li>
          <li>Greenpeace Augsburg</li>
          <li>Quartiersmanagement Rechts-der-Wertach</li>
        </ul>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/23.jpeg" alt="Fest1" />
        </div>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/16.jpeg" alt="Fest1" />
        </div>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/11.jpeg" alt="Fest1" />
        </div>
        <br></br>
      </p>
      <hr />
    </>
  );
}

// In der Datei "./views/Idee.tsx"
export const Idee = () => {
  // Hier befindet sich die Implementierung der Idee-Komponente
};
