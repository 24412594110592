import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";
import { FloatLeftImage } from "../components/Image";

export function Kontakt() {
  return (
    <>
      <br></br>
      <h1 id="unterstuetzeuns">Unterstuetze uns</h1>
      <br></br>
      <p>
        Für die finanzielle Unterstützung in Form einer Spende sind wir sehr dankbar. Die Kontoverbindung hierfür ist Qualle - Quartier für Alle e.V., <br></br><b>IBAN: DE55 4306 0967 1329 9742 00</b>. Wir stellen eine Zuwendungsbescheinigung aus. Vielen Dank!<br></br>
        <br></br>
        Weitere Unterstützungsmöglichkeiten bietet eine Mitgliedschaft im Verein. Die reguläre Mitgliedschaft beträgt 12€ im Jahr. Fördermitglieder unterstützen uns mit 120€ jährlich.<br></br>
        <br></br>
        <CollapsibleCard
        id="InLeichterSprache0"
        title={"Antrag zur Mitgliedschaft"}
        content={
         <>
          <div className="d-flex flex-column">
            <p>Werde Mitglied im Verein <i>Qualle - Quartier für Alle e.V.</i><br></br>
            Lade das Dokument runter und schicke es ausgefüllt an info@qualleaugsburg.de
            </p><br></br>
            <FloatLeftImage src="img/Beitrittsformular Qualle 2024.png" alt="15.08." maxWidth="950px" />
         </div>
         <i>*Der Jahresmitgliedsbeitrag für eine reguläre Mitgliedschaft wurde in der Mitgliederversammlung am 17.12.2023 auf 12€ festgelegt.</i>
         </>

        }
       />
       <br></br>
        <i>Transparenz:<br></br>
         Wir haben jährliche Fixkosten von etwa 7500€, die wir für die Miete des Quartierszentrums, Strom, Heizung und Internet sowie Versicherungen bezahlen. Durch Mitgliedsbeiträge erhalten wir von unseren aktuell knapp 40 Mitgliedern im Jahr etwa 500€. Ohne die finanzielle Unterstützung durch Stiftungen, Fördermittel und Privatpersonen kann der Verein seine laufenden Kosten nicht decken.</i><br></br>
      </p>
      <br></br>
      <hr />

      <h1 id="adresse">Wo ist das Quartierszentrum?</h1>
      <br></br>
      <p>
        Ihr findet uns auf der Ecke Mittelstraße/Wiesenstraße. Schreibt uns gerne eine Mail, wenn ihr vorbeikommen wollt, oder die Räumlichkeiten anderweitig nutzen möchtet.<br></br>
        Wir treffen uns jeden Mittwoch um 18 Uhr im Quartierszentrum zum offenen, wöchentlichen Plenum. Ihr seid jederzeit herzlich eingeladen.<br></br>
        <br></br>
        Postalische Adresse:<br></br>
        <b>Qualle - Quartier für Alle e.V.<br></br>
        Wiesenstraße 5<br></br>
        86153 Augsburg</b><br></br>
        <br></br>
        <a
          href="https://www.google.com/maps/place/Quartierszentrum/@48.3796849,10.8875443,15.78z/data=!4m6!3m5!1s0x479ebdef0c800819:0xe0e3b0d2f6e0451d!8m2!3d48.380983!4d10.8860758!16s%2Fg%2F11mwwc8p09?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>Link zu Maps</i>
        </a>
        <br></br>
        <br></br>
      </p>
      <div className="d-flex flex-column">
        <img
          style={{ maxWidth: "950px", width: "100%", marginBottom: "1em", marginTop: "-1em" }}
          src="img/Karte QZ.png"
          alt="Karte QZ"
          className="float-left"
        />
        </div>
        <br></br>
      <hr />


      <h1 id="befreundetegruppen">Befreundete Gruppen</h1>
      <p>
        In Augsburg passiert viel. Es gibt einige Gruppen, die sich um
        unterschiedliche Themengebiete wie Klimagerechtigkeit, soziale
        Gerechtigkeit, Dekolonialisierung, Wohnraum, Integration und kulturelle
        Vielfalt kümmern. Hier haben wir sie für euch aufgelistet. Jede dieser
        Gruppen freut sich über Unterstützung. Danke für eure Arbeit!<br></br>
        <br></br>

        <a
          href="https://augsburgfueralle.noblogs.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Augsburg für Alle
        </a>
        <br></br>
        <a
          href="https://ganzebaeckerei.blogspot.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Die Ganze Bäckerei
        </a>
        <br></br>
        <a
          href="https://frauenstreikaux.blackblogs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          F*Streik Augsburg
        </a>
        <br></br>
        <a
          href="https://grandhotel-cosmopolis.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Grandhotel Cosmopolis e.V.
        </a>
        <br></br>
        <a
          href="https://paradieschen-augsburg.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Pa*radieschen Augsburg
        </a>
        <br></br>
        <a
          href="https://unserhausev.wordpress.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Unser Haus e.V.
        </a>
      </p>
      <br></br>
      <p>
        Einen tollen Überblick über Veranstaltungen und Initiativen in
        Augsburg gibt es bei{" "}
        <a href="https://auxpunks.uber.space/" rel="noreferrer" target="_blank">
          AuxPunks
        </a>
        .
      </p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}
