import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { FloatLeftImage, ZoomableImage } from "../components/Image";

export function Aktuelles() {
  return (
    <>
      <div className="d-flex flex-column">
       <br></br>
        <img
          style={{ maxWidth: "500px", width: "100%", margin: "auto", marginBottom: "-2em" }}
          src="img/Aktuelles.png"
          alt="Logo"
          className="zoomable-image"
        />
      </div>
      <div className="mt-3">
        <br />
        <br></br>
        <br></br>
        <hr />


        <i>29.06.2024</i><br></br>
        <br></br>
        <p>
        <b>Das Mittelstraßenfest 2024!</b><br></br>
        <br></br>
        Was für ein gelungenes Fest. Wir schwelgen immer noch in Erinnerungen. Hier ein paar Impressionen:<br></br>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <figure>
            <ZoomableImage src="img/fest 1.jpg" alt="Kippen1" />
            <figcaption><br></br><br></br>Menschen verschiedenster Altersgruppen & soziokultureller Hintergründe<br></br> haben das Quartier belebt. <br></br>Danke an Alle, die dabei waren!</figcaption>
    </figure>
          </div>
          <div className="image-container">
            <ZoomableImage src="img/fest 8.jpg" alt="Kippen4" />
          </div>
        </div>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <ZoomableImage src="img/fest 10.jpg" alt="Kippen2" />
          </div>
          <div className="image-container">
            <ZoomableImage src="img/fest 3.jpg" alt="Kippen5" />
          </div>
          <div className="image-container">
            <ZoomableImage src="img/fest 4.jpg" alt="Kippen2" />
          </div>
        </div>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <ZoomableImage src="img/fest 5.jpg" alt="Kippen5" />
          </div>
          <div className="image-container">
            <ZoomableImage src="img/fest 6.jpg" alt="Kippen2" />
          </div>
        </div>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <ZoomableImage src="img/fest 7.jpg" alt="Kippen5" />
          </div>
          <div className="image-container">
            <ZoomableImage src="img/fest 9.jpg" alt="Kippen2" />
          </div>
          <div className="image-container">
            <ZoomableImage src="img/fest 2.jpg" alt="Kippen3" />
          </div>
        </div>
        </p>
        <hr />


        <i>08.06.2024</i><br></br>
        <br></br>
        <p>
        <b>Mittelstraßenfest 2024</b><br></br>
        <br></br>
        In zwei Wochen ist es endlich so weit!<br></br>
        <br></br>
        Am Samstag, den <b>22.06. ab 15 Uhr findet in Rechts-der-Wertach das Mittelstraßenfest 2024</b> statt.<br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Plakat MittelstrFest.png" alt="test" />
        </div>
        <br></br>
        Auf der Kreuzung Mittelstraße-Wiesenstraße erwartet dich ein buntes Fest mit Kinderprogramm, Workshops, Künstler*innen, Tauschbasars, leckerem Essen und natürlich guter Musik.<br></br>
        Für Alle ist was mit dabei - Eine tolle Gelegenheit deine Nachbarschaft kennenzulernen und gemeinsam Spaß zu haben. Wir freuen uns auf jeden, der vorbeikommt!<br></br>
        <br></br>
        (Ein gemeinschaftlich organisiertes Projekt von dem Quartiersprojekt Qualle - Quartier für Alle e.V. und dem Quartiersmanagement Rechts-der-Wertach)<br></br>
        <br></br>
        _____<br></br>
        <br></br>
        The time has finally come in two weeks!<br></br>
        <br></br>
        On 22.06. from 3 pm, the Mittelstraßenfest 2024 will take place in Rechts-der-Wertach.<br></br>
        <br></br>
        A colorful festival with a children's program, workshops, artists, swap bazaars, delicious food and of course good music awaits you at the intersection of Mittelstraße and Wiesenstraße.<br></br>
        There's something for everyone - a great opportunity to get to know your neighborhood and have fun together. We look forward to seeing everyone who comes along!<br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
Flo
        <br></br>
        <FloatLeftImage src="img/MSF 2024 - Timetable 2.png" alt="test" />
        </div>
        </p>
        <hr />

        <i>23.05.2024</i><br></br>
        <br></br>
        <p>
        <b>Spendenkampagne gestartet</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/mehrgeben1.png" alt="test" />
        </div>
        <br></br>
        Wir brauchen deine Unterstützung!<br></br>
        <br></br>
        Seit gestern läuft unsere Spendenkampagne über das Portal der Stadtsparkasse Augsburg: <a
          href="https://www.mehrgeben.de/project/nachbarschaftscafe-in-rechts-der-wertach-augsburg-oberhausen/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          mehrgeben.de
        </a><br></br>
        <br></br>
        Ihr könnt uns in den nächsten 8 Wochen finanziell unterstützen. Jeder Betrag zählt!<br></br>
        <br></br>
        Transparenz:<br></br>
        Wir sind ein junge Verein, der allein durch Ehrenamt funktioniert. Leider sind die Unterstützungsmöglichkeiten seitens der Stadt Augsburg begrenzt. Damit wir das Quartierszentrum (Miete monatlich 600€), das Nachbarschaftscafé und unsere Projekte umsetzen können, sind wir darauf angewiesen fortlaufend Förderanträge zu schreiben.<br></br>
        Aktuell haben wir rund 3000€ auf dem Konto. Damit können wir uns die Miete des Quartierszentrums bis zum Herbst leisten. Um jedoch regelmäßig das Nachbarschaftscafé als Treffpunkt für die Menschen in Rechts-der-Wertach zu öffnen, brauchen wir Deine Unterstützung!<br></br>
        -----------------------------------<br></br>
        <br></br>
        We need your support!<br></br>
        <br></br>
        Our fundraising campaign has been running since yesterday via the portal of Stadtsparkasse Augsburg <a
          href="https://www.mehrgeben.de/project/nachbarschaftscafe-in-rechts-der-wertach-augsburg-oberhausen/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          mehrgeben.de
        </a><br></br>
        You can support us financially over the next 8 weeks. Every amount counts!<br></br>
        <br></br>
        Transparency:<br></br>
        We are a young organisation that functions solely through voluntary work. Unfortunately, support from the city of Augsburg is limited. In order to be able to run the neighbourhood centre (monthly rent €600), the neighbourhood café and our projects, we are dependent on writing funding applications on an ongoing basis. <br></br>
        We currently have around €3000 in our account. This will enable us to afford the rent for the neighbourhood centre until the autumn. However, in order to regularly open the neighbourhood café as a meeting place for the people of Rechts-der-Wertach, we need your support!<br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/mehrgeben2.png" alt="test" />
        </div>
        </p>
        <hr />

        <i>11.05.2024</i><br></br>
        <br></br>
        <p>
        <b>Nächstes Planungstreffen: Mittelstraßenfest 2024</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/3. Planungstreffen MittelstrFest.png" alt="test" />
        </div>
        <br></br>
        Die Planungen für das Mittelstraßenfest 2024...<br></br>
        <br></br>
        ...am 22. Juni von 15 bis 22 Uhr...<br></br>
        <br></br>
        ...laufen auf Hochtouren. Wir brauchen deine Unterstützung! Das nächste Planungstreffen findet bereits am kommenden Montag, den 13. Mai um 18 Uhr im Quartierszentrum statt.<br></br>
        Melde dich gerne über info@qualleaugsburg.de<br></br>
        -------------------------------------<br></br>
        <br></br>
        The plans for the Mittelstraßenfest 2024...<br></br>
        <br></br>
        ...on 22 June from 3 to 10 pm...<br></br>
        <br></br>
        ...are in full swing. We need your support! The next planning meeting will take place next Monday, 13 May at 6 pm in the neighbourhood centre.<br></br>
        <br></br>
        Please get in touch via info@qualleaugsburg.de<br></br>
        </p>
        <hr />


        <i>08.05.2024</i><br></br>
        <br></br>
        <p>
        <b>Die nächsten Termine fürs Nachbarschaftscafé</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/NCafé - Wochenplan Mai 2.png" alt="Müll25.11." />
        </div>
        <br></br>
        Wir laden euch diese Woche ein ins Nachbarschaftscafé...<br></br>
        <br></br>
        Do, 09. Mai von 14 - 20 Uhr<br></br>
        Fr, 10. Mai von 15 - 18 Uhr<br></br>
        - Treffen der Incredible Edible Gruppe!<br></br>
        Sa, 11. Mai von 14 - 19 Uhr<br></br>
        - Küche für Alle ab 14 Uhr!<br></br>
        So, 12. Mai von 16 - 20 Uhr<br></br>
        - Mit kleiner Jam-Session!<br></br>
        <br></br>
        Wir freuen uns auf dich!<br></br>
        </p>
        <hr />

        <i>25.04.2024</i><br></br>
        <br></br>
        <p>
        <b>Die nächsten Termine fürs Nachbarschaftscafé</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/NCafé - Wochenplan.png" alt="Müll25.11." />
        </div>
        <br></br>
        Wir freuen uns ankündigen zu können:<br></br>
        <br></br>
        Die ersten "Öffnungszeiten" für das nicht-kommerzielle Nachbarschaftscafé in Rechts-der-Wertach. Genauer Ort ist das Quartierszentrum auf der Ecke Wiesenstraße/Mittelstraße. An folgenden Terminen kannst du vorbeikommen, Menschen aus dem Quartier und drumherum begegnen, dich vernetzen und austauschen, oder einfach eine gute Zeit haben:<br></br>
        <br></br>
        25. April von 14 bis 18 Uhr<br></br>
        27. April von 12 bis 19 Uhr (Brotzeit für Alle ab 14 Uhr)<br></br>
        28. April von 16 bis 20 Uhr<br></br>
        03. Mai von 14 bis 19 Uhr<br></br>
        04. Mai von 13 bis 19 Uhr<br></br>
        <br></br>
        Auf freiwilliger Spendenbasis wird es Kaffee, Tee und Wasser und ab und zu auch mal ein paar Snacks oder einen selbstgebackenen Kuchen geben. Komm vorbei und lerne das Café und das Quartiersprojekt kennen. Gemeinsam für eine lebenswerte, gemeinschaftliche Nachbarschaft!<br></br>
        ---------------------------------<br></br>
        <br></br>
        We are pleased to announce:<br></br>
        <br></br>
        The first ‘opening hours’ for the non-commercial neighbourhood café in Rechts-der-Wertach. The exact location is the neighbourhood centre on the corner of Wiesenstraße/Mittelstraße. You can drop by on the following dates, meet people from the neighbourhood and the surrounding area, network and exchange ideas, or simply have a good time:<br></br>
        25 April from 2 to 6 pm<br></br>
        27 April from 12 to 7 pm (snack for everyone from 2 pm) <br></br>
        28 April from 4 to 8 pm<br></br>
        03 May from 2 to 7 pm<br></br>
        04 May from 1 to 7 pm<br></br>
        <br></br>
        Coffee, tea and water and occasionally a few snacks or a home-baked cake will be available on a voluntary donation basis. Come along and get to know the café and the neighbourhood project. Together for a neighbourhood worth living in!<br></br>
        </p>
        <hr />

        <i>08.04.2024</i><br></br>
        <br></br>
        <p>
        <b>1. Planungstreffen Mittelstraßenfest 2024 - Mittwoch, 10.04. um 10 Uhr</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/1. Treffen Mittelstraßenfest.png" alt="Müll25.11." />
        </div>
        <br></br>
        Das Quartiersmanagement @rechts_der_wertach & @qualle_augsburg laden ein...<br></br>
        <br></br>
        Zum ersten Planungstreffen für das Mittelstraßenfest 2024,<br></br>
        <br></br>
        Am Mittwoch, den 10. April 2024 um 20 Uhr im Quartierszentrum Rechts-der-Wertach (Wiesenstraße 5)<br></br>
        <br></br>
        In diesem Jahr wird das Mittelstraßenfest auf der Ecke vor dem Quartierszentrum seinen Ausgangspunkt haben. Von dort aus planen wir in alle 4 Himmelsrichtungen Stände, Workshops zu aktuellen gesellschaftlichen Themen, Musik, und vieles mehr - eben genau das, was wir daraus machen!<br></br>
        <br></br>Wir freuen uns riesig über deine Mitarbeit.<br></br>
        Bei Fragen oder Vorschlägen wende dich gerne an kontakt@rechts-der-wertach.de oder info@qualleaugsburg.de<br></br>
        <br></br>
        -----------------------------------------------------------<br></br>
        The neighbourhood management & the Qualle invite you...<br></br>
        <br></br>
        To the first planning meeting for the Mittelstraßenfest 2024,<br></br>
        <br></br>
        On Wednesday, 10 April 2024 at 8 pm in the Quartierszentrum Rechts-der-Wertach (Wiesenstraße 5)<br></br>
        <br></br>
        This year, the Mittelstraßenfest will have its starting point on the corner in front of the neighbourhood centre. From there, we are planning stalls, workshops on current social issues, music and much more in all 4 directions - just what we make of it!<br></br>
        <br></br>
        We look forward to your cooperation.<br></br>
        <br></br>
        If you have any questions or suggestions, please contact kontakt@rechts-der-wertach.de or info@qualleaugsburg.de<br></br>
        </p>
        <hr />

        <i>03.04.2024</i><br></br>
        <br></br>
        <p>
        <b>RepairCafé - Samstag, 06.04.</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/RepairCafé0604.png" alt="Müll25.11." />
        </div>
        <br></br>
        Am Samstag vor der Demo für bezahlbaren Wohnraum:<br></br>
        <br></br>
        ...<b>Das erste RepairCafé im Quartierszentrum</b>...<br></br>
        <br></br>
        Folgende Repair-Slots wird es geben:<br></br>
        <i>Elektro-Repair (11-13 Uhr):</i><br></br>
        - Kaputte Haushaltsgeräte aller Art werden unter die Lupe genommen...<br></br>
        <i>Näh- & Upcycling-Session (11-15 Uhr):</i><br></br>
        - Bring Kleidung mit Löchern, aufgegangenen Nähten oder nähe neues zusammen...<br></br>
        <i>BikeRepair (12-15 Uhr):</i><br></br>
        - Wir reparieren zusammen Reifen, und kleine Defekts an deinem Fahrrad...<br></br>
        <br></br>
        Wir freuen uns auf dich!<br></br>
        ------------------------------<br></br>
        On Saturday before the demo for affordable housing:<br></br>
        <br></br>
        ...The first RepairCafé in Quartierszentrum...<br></br>
        <br></br>
        The following repair slots will be available:<br></br>
        <i>Electrical repair (11 am - 1 pm):</i><br></br>
        - Broken household appliances of all kinds will be scrutinised...<br></br>
        <i> Sewing & upcycling session (11 am - 3 pm):</i><br></br>
        - Bring clothes with holes, undone seams or sew together new...<br></br>
        <i>BikeRepair (12-15 h):</i><br></br>
        - Together we repair tyres, and small defects on your bike...<br></br>
        <br></br>
        We look forward to seeing you!<br></br>
        </p>
        <hr />

        <i>21.03.2024</i><br></br>
        <br></br>
        <p>
        <b>Nächste Küche für Alle - Samstag, 23.03.</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Suppenküche 23.03. (2).png" alt="Müll25.11." />
        </div>
        <br></br>
        Die 14-tägig stattfindende Küche für Alle macht richtig Spaß. Immer mehr Nachbar*innen kommen zusammen und verbringen den Nachmittag bei uns am QZ. Da wollen wir glatt ein Event einschieben:<br></br>
        <br></br>
        Am kommenden Samstag, 23. März, 14-19h, Quartierszentrum Rechts-der-Wertach:<br></br>
        <b>Küche für Alle (& gemütliches Banner malen)</b><br></br>
        <br></br>
        Komm rum!<br></br>
        ---------------------<br></br>
        <br></br>
        The fortnightly Kitchen for All is great fun. More and more neighbors are coming together and spending the afternoon with us at the QZ. That's why we want to add an event:<br></br>
        <br></br>
        Next Saturday, 14-19h, Quartierszentrum Rechts-der-Wertach:<br></br>
        <b>Kitchen for all (& cozy banner painting)</b><br></br>
        <br></br>
        Come around!<br></br>
        </p>
        <hr />

        <i>15.02.2024</i><br></br>
        <br></br>
        <p>
        <b>KickOff-Treffen: Gartenprojekte im Quartier - 19.02. um 19 Uhr im QZ</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/KickOff-Treffen Gartenprojekte.png" alt="Müll25.11." />
        </div>
        <br></br>
        Die Qualle läd ein: Am kommenden Montag, den 19.02., treffen wir uns um 19 Uhr im Quartierszentrum (Wiesenstraße 5) um über potenzielle Pflanz- und Gartenprojekte im Quartier Rechts-der-Wertach zu sprechen. Wir haben schon einige Ideen, wollen diese mit euch teilen und gemeinsam Brainstormen, wie wir die Vorschläge umsetzen könnten. Vielleicht finden wir ja schon einen Vorschlag auf den wir uns einigen können und starten schon mit der Vorbereitung.<br></br>
        <br></br>
        Lasst uns gemeinsam die Hände schmutzig machen!<br></br>
        ----------------------------------------------------<br></br>
        <br></br>
        The Qualle invites you: Next Monday, 19.02., we will meet at 7 pm in the neighbourhood centre (Wiesenstraße 5) to talk about potential planting and gardening projects in the Rechts-der-Wertach neighbourhood. We already have some ideas, want to share them with you and brainstorm together how we could implement the suggestions. Maybe we can already find a proposal that we can agree on and start preparing.<br></br>
        <br></br>
        Let's get our hands dirty together!<br></br>
        <br></br>
        </p>
        <hr />

        <i>10.02.2024</i><br></br>
        <br></br>
        <p>
        <b>Quartiersflimmern am 12.02.: "Tomorrow"</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/QZflimmern Feb 24.png" alt="Müll25.11." />
        </div>
        <br></br>
        Herzliche Einladung zum ersten...<br></br>
        <br></br>
        QUARTIERSFLIMMERN!<br></br>
        <br></br>
        Am kommenden Montag schauen wir im Quartierszentrum den Film "Tomorrow - die Welt ist voller Lösungen". Zur Primetime, um 20:15, gehts los.<br></br>
        <br></br>
        ------------------------------------------<br></br>
        A warm invitation to the first...<br></br>
        <br></br>Movienight in the Quartierszentrum!<br></br>
        <br></br>
        Next Monday we will be watching the film "Tomorrow - the world is full of solutions" in the neighbourhood centre. It starts at primetime, at 20:15.<br></br>
        <br></br>
        Bist Du dabei/are you in?<br></br>
        </p>
        <hr />


        <i>08.02.2024</i><br></br>
        <br></br>
        <p>
        <b>Heute eröffnet die Bibliothek der Dinge!</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/bibfinal.jpeg" alt="Müll25.11." />
        </div>
        <br></br>
        Werkzeug ist zum teilen da.<br></br>
        Leih dir gegen Pfand aus, was Du brauchst.<br></br>
        <br></br>

        <a href="https://www.qualle-augsburg.de/quartierszentrum#bibliothekderdinge" rel="noreferrer" target="_blank">
        Hier geht es zur Seite des Projekts
        </a><br></br>
        <br></br>
        Ab sofort können sich alle Menschen aus Rechts-der-Wertach und drumherum Werkzeug und Dinge für den alltäglichen Gebrauch ausleihen. Wieso sollte jeder Haushalt einen eigenen Werkzeugkoffer besitzen?<br></br>
        <br></br>
        <u>Wie funktioniert die Ausleihe?</u><br></br>
        <ul>
          <li>Check unser Sortiment aus!</li>
          <li>Reserviere per Mail und schlage Termine vor, wenn du den Gegenstand im Quartierszentrum Rechts-der-Wertach abholen kannst: <br></br>
          info@qualleaugsburg.de</li>
        </ul>
        <br></br>
        ------------------------------------------------------------------------------------<br></br>
        <b>The library of things opens today!</b><br></br>
        <br></br>
        Tools are there to share.<br></br>
        Borrow what you need for a deposit.<br></br>
        <br></br>
        <a href="https://www.qualle-augsburg.de/quartierszentrum#bibliothekderdinge" rel="noreferrer" target="_blank">
          Click here, to get to the page of the project</a><br></br>
        <br></br>
        From now on, everyone from Rechts-der-Wertach and the surrounding area can borrow tools and things for everyday use. Why should every household have its own toolbox?<br></br>
        <br></br>
        <u>How does the loan work?</u><br></br>
        <ul>
          <li>Check out our range of material! </li>
          <li>Reserve by email and suggest dates when you can collect the item from the Quartierszentrum Rechts-der-Wertach:<br></br>
          info@qualleaugsburg.de</li>
        </ul>
        </p>
        <hr />

        <i>30.01.2024</i><br></br>
        <br></br>
        <p>
        <b>Nach der Demo gegen Rechts: (Suppen-) Küche für Alle - 03.02. ab 16 Uhr</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Suppenküche03.02..png" alt="Müll25.11." />
        </div>
        <br></br>
        Am kommenden Samstag startet die nächste (Suppen-) Küche für Alle im Quartierszentrum. Nach der Demo gegen Rechts <a href="https://www.augsburg-gegen-rechts.de" rel="noreferrer" target="_blank">
        (www.augsburg-gegen-rechts.de)
        </a> treffen wir uns voraussichtlich gegen 17 Uhr auf der Ecke Mittelstraße/Wiesenstraße und lassen den Samstag antifaschistisch ausklingen. Wir freuen uns auf Dich!<br></br>
        <br></br>
        #räumeschaffenfürveränderung<br></br>
        #augsburggegenrechts<br></br>
        #quartierfüralle<br></br>
        #thinkglobalactlocal<br></br>
        <br></br>
        </p>
        <hr />

        <i>24.01.2024</i><br></br>
        <br></br>
        <p>
        <b>Update des Foodverteilers</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Update 1.JPG" alt="Müll25.11." />
        </div>
        <br></br>
        DER FOODVERTEILER IST EIN GEMEINSCHAFTSPROJEKT UND KANN NUR FUNKTIONIEREN, WENN ALLE MITMACHEN!<br></br>
        <br></br>
        Heute haben wir den Foodverteiler geupdatet. Ab sofort gibt es ein Hygieneprotokoll. Mindestens einmal in der Woche muss der Verteiler geputzt und nicht mehr essbare Lebensmittel aussortiert werden.<br></br>
        <br></br>Dazu brauchen wir DICH! Wenn du mithelfen möchtest, komm in diese <a href="https://t.me/+zJ2v9Fr7lDA5NjUy" rel="noreferrer" target="_blank">
        Telegramgruppe
        </a><br></br>
        oder schreibe eine Mail an info@qualleaugsburg.de<br></br>
        <br></br>
        Auch wenn du Essensretter*in werden möchtest, bist du hier genau richtig.<br></br>
        <br></br>
        Also: Gemeinsam schaffen wir es, dass dieses tolle Projekt weiterhin bestehen bleibt!!!<br></br>
        <br></br>
        -----<br></br>
        English version:<br></br>
        <br></br>
        THE FOOD DISTRIBUTOR IS A COMMUNITY PROJECT AND CAN ONLY WORK IF EVERYONE PARTICIPATES!<br></br>
        <br></br>
        Today we have updated the food distributor. From now on there is a hygiene protocol. At least once a week, the distributor must be cleaned and food that is no longer edible must be sorted out.<br></br>
        <br></br>
        We need YOU to do this! If you would like to help, join this <a href="https://t.me/+zJ2v9Fr7lDA5NjUy" rel="noreferrer" target="_blank">
        telegram group
        </a><br></br>
        or write an email to info@qualleaugsburg.de<br></br>
        <br></br>
        Even if you want to become a food rescuer, you've come to the right place.<br></br>
        <br></br>
        So: Together we can make sure that this great project continues!!!<br></br>
        </p>
        <hr />

        <i>18.01.2024</i><br></br>
        <br></br>
        <p>
        <b>Diesen Samstag: (Suppen-) Küche für Alle!</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/(Suppen-)Küche für Alle!.png" alt="Müll25.11." />
        </div>
        <br></br>
        Lust auf eine leckere warme Suppe am Samstag Nachmittag?<br></br>
        Eine gute Möglichkeit um das Quartierszentrum und die Menschen der Qualle kennenzulernen! Was das kulinarische Angebot genau hergibt, können wir noch nicht sagen - hängt von dem ab, was wir in den nächsten Tagen an Lebensmitteln retten.<br></br>
        <br></br>
        Wann? Samstag, 20.01. von 13-16h<br></br>
        Wo? Quartierszentrum Rechts-der-Wertach, Ecke Mittelstraße/Wiesenstraße<br></br>
        <br></br>
        Alles auf Spendenbasis. Wir freuen uns auf dich!<br></br>
        -----<br></br>
        (Soup) cuisine for everyone!<br></br>
        <br></br>
        Fancy a tasty hot soup on a Saturday afternoon?<br></br>
        A great way to get to know the neighbourhood centre and the people of the Qualle! We can't yet say exactly what the culinary offer will be - it depends on what food we save over the next few days.<br></br>
        <br></br>
        When? Saturday, 20.01. from 13-16h<br></br>
        Where? Quartierszentrum Rechts-der-Wertach, corner Mittelstraße/Wiesenstraße<br></br>
        <br></br>
        Everything on a donation basis. We look forward to seeing you!<br></br>
        </p>
        <hr />


        <i>09.01.2024</i><br></br>
        <br></br>
        <p>
        <b>Bibliothek der Dinge eröffnet bald!</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Bibliothek der Dinge.png" alt="Müll25.11." />
        </div>
        <br></br>
        In einem Monat, am 08. Februar, eröffnen wir die Bibliothek der Dinge - und dafür brauen wir Deine Hilfe:<br></br>
        Wie Du das Projekt unterstützen kannst, erfährst du <a href="https://www.qualle-augsburg.de/quartierszentrum#bibliothekderdinge" rel="noreferrer" target="_blank">
         hier
        </a><br></br>
        <br></br>
        -----<br></br>
        In one month, on February 8th, we will open the Library of Things - and we need your help:<br></br>
        You can find out how you can support the project <a href="https://www.qualle-augsburg.de/quartierszentrum#bibliothekderdinge" rel="noreferrer" target="_blank">
         here
        </a>
        </p>
        <hr />

        <i>31.12.2023</i><br></br>
        <br></br>
        <p>
        <b>2023 haben wir gerockt - 2024 kann kommen</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/2023 gerockt.jpeg" alt="Müll25.11." />
        </div>
        <br></br>
        ...obwohl wir erst seit Juni in Rechts-der-Wertach aktiv sind. In diesen sieben Monaten ist einiges passiert. Die Oase auf der Mittelstraße, der Tauschschrank und der Foodverteiler, die Kippenaktion, das Straßenfest im Oktober - und schließlich haben wir das Quartierszentrum ins Leben gerufen.<br></br>
        <br></br>
        Wir sind auf jeden Fall ready für 2024. Mach mit - das nächste Treffen ist am Mittwoch, den 03. Januar um 18 Uhr!<br></br>
        <br></br>
        </p>
        <hr />

        <i>12.12.2023</i><br></br>
        <br></br>
        <p>
        <b>Tag der offenen (Quartierszentrums-)Tür - Samstag, den 16. Dezember, von 15 bis 21 Uhr</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/16.12..png" alt="Müll25.11." />
        </div>
        <br></br>
        Wir laden ein zum nachbarschaftlichen Austausch und Kennenlernen. Mit Glühwein, Suppe und Lebkuchen auf Spendenbasis wollen wir über die Chancen uns Potentiale von Quartiersarbeit in Rechts-der-Wertach ins Gespräch kommen.<br></br><br></br>
        Welche Dinge beschäftigen Dich in deiner direkten Nachbarschaft? Welche Projekte können wir gemeinsam umsetzen, um unser Quartier lebenswerter zu machen? Wie können wir Räume gestalten, dass sich Nachbar*innen wieder kennen und unterstützen?<br></br>
        <br></br>
        Adresse: Ecke Mittelstraße/Wiesenstraße (Wiesenstraße 5, 86153 Augsburg)<br></br>
        <br></br>
        <a href="https://shorturl.at/jvJR4" rel="noreferrer" target="_blank">
         Google Maps Adresse
        </a>
        <br></br>
        <br></br>
        Mach mit - wir freuen uns auf Dich!<br></br>
        <br></br>
        -----<br></br>
        Saturday, 16 December, from 3 to 9 pm<br></br>
        <br></br>
        We invite you to a neighbourly exchange and get to know each other. With mulled wine, soup and gingerbread on a donation basis, we want to talk about the opportunities and potential of neighbourhood work in Rechts-der-Wertach.<br></br>
        <br></br>
        What issues are you concerned about in your immediate neighbourhood? What projects can we implement together to make our neighbourhood a better place to live? How can we create spaces where neighbours can get to know and support each other again?<br></br>
        <br></br>
        Address: Corner of Mittelstraße/Wiesenstraße (Wiesenstraße 5, 86153 Augsburg)<br></br>
        <a href="https://shorturl.at/jvJR4" rel="noreferrer" target="_blank">
         Google Maps Adress
        </a><br></br>
        <br></br>
        All information at <a href="https://www.qualle-augsburg.de" rel="noreferrer" target="_blank">
         www.qualle-augsburg.de
        </a><br></br>
        <br></br>
        Join in - we look forward to seeing you!<br></br>
        </p>
        <br></br>
        <hr />

        <i>20.11.2023</i><br></br>
        <br></br>
        <p>
        <b>Müllsammelaktion am kommenden Samstag</b><br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/SP - Müllsammelaktion.png" alt="Müll25.11." />
        </div>
        <br></br>
        Am kommenden Samstag - 25. November - starten wir wieder eine Müllsammelaktion. Wir treffen uns um 12 Uhr am Tauschschrank & Foodverteiler an der Ecke Wertachstraße/Wolfgangstraße (Wolfgangstraße 2).<br></br>
        <br></br>
        Mit Musik & guter Laune legen wir bei der Aktion besonderes Augenmerk auf die 12 Bäume auf der Wertachstraße, da in diesen immer wieder Unmengen an Kippenstummeln landen. Im Rahmen der Kampagne "Kippenfreie Wertachstraße" wollen wir so die Sensibilisierung steigern und den Bäume Luft zum atmen geben.<br></br>
        <br></br>
        Dabei werden wir von Greenpeace Augsburg mit Material und moralischer Unterstützung supportet. Die lieben Menschen von Greenpeace veranstalten ab 14 Uhr einen Kleidertausch im City Cafe und sind daher nicht bei der Aktion mit dabei.<br></br>
        <br></br>
        Wir freuen uns, wenn Du mit am Start bist!<br></br>
        ----------------------------------------------------------<br></br>
        English version:<br></br>
        <br></br>
        Next Saturday - 25 November - we are starting another waste collection campaign. We will meet at 12 noon at the swap cupboard & food distributor on the corner of Wertachstraße/Wolfgangstraße (Wolfgangstraße 2).<br></br>
        <br></br>
        With music and good vibrations, we will be paying particular attention to the 12 trees on Wertachstraße, as a huge amount of cigarette butts always end up in them. As part of the "Fag-free Wertachstraße" campaign, we want to raise awareness and give the trees air to breathe.<br></br>
        <br></br>
        We are supported by Greenpeace Augsburg with material and moral support. The lovely people from Greenpeace are organising a clothes swap in the City Cafe from 2 pm and are therefore not taking part in the action.<br></br>
        <br></br>
        We'd love you to join us!<br></br>

        <br></br>
        </p>
        <br></br>
        <hr />

        <i>28.10.2023</i><br></br>
        <br></br>
        <p>
        <b>Tauschschrank steht jetzt in der Wolfgangstraße</b><br></br>
        <br></br>
        Am Samstag haben wir die Oase abgebaut. Im Zuge dessen haben wir auch den heißbegehrten Tauschschrank umgestellt.
        Neuer Ort: Direkt neben der <a href="https://www.qualle-augsburg.de/projekte#Futterquelle" rel="noreferrer" target="_blank">
         Futterquelle
        </a> in der Wolfgangstraße.<br></br>
        <br></br>
        Bring einfach vorbei, was du nicht mehr brauchst und nimm mit, was dir gefällt!<br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/TS1.jpg" alt="Fest1" />
        </div>
        <br></br>

        </p>
        <br></br>
        <hr />

        <i>17.10.2023</i><br></br>
        <br></br>
        <p>
        <b>Straßenfest 14. & 15. Oktober</b><br></br>
        <br></br>
        Was für ein tolles Wochenende!<br></br>
        Am Samstag fielen noch einige Tropfen vom Himmel - es war nicht viel los. Am Sonntag hingegen hatten wir eine tolle Zeit. Gegen 14 Uhr kam allmählich die Sonne raus und schenkte uns einen guten Vibe. Menschen aus dem Quartier kamen vorbei und lernten sich kennen. Hier geht was in Rechts der Wertach. Wir freuen uns schon auf das nächste Mal!<br></br>
        <br></br>
        <div className="d-flex flex-column">
        <video
          style={{ maxWidth: "700px", width: "100%", marginBottom: "0em" }}
          className="float-left"
          controls
        >
          <source
            src="Video Björn.mp4"
            type="video/mp4"
          />
        </video>
      </div>
        <br></br>
        <br></br>
        Danke an alle Menschen und Gruppen, die das Fest unterstützt haben:<br></br>
        <ul>
          <li>Sozialer Mampf</li>
          <li>Pa*radieschen Hausprojekt</li>
          <li>Sherlo e.V.</li>
          <li>Augsburg für Alle</li>
          <li>Kitsch und Krawall</li>
          <li>Trio M.3</li>
          <li>DJ vanderFelden</li>
          <li>Izy</li>
          <li>Björn</li>
          <li>Vintage & Kraetze</li>
          <li>Greenpeace Augsburg</li>
          <li>Quartiersmanagement Rechts der Wertach</li>
        </ul>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/23.jpeg" alt="Fest1" />
        </div>
        <br></br>

        <div className="d-flex flex-column">
        <FloatLeftImage src="img/16.jpeg" alt="Fest1" />
        </div>
        <br></br>

        <div className="d-flex flex-column">
        <FloatLeftImage src="img/11.jpeg" alt="Fest1" />
        </div>

        <br></br>
        <br></br>
        Auch die Kampagne <a
                href="https://www.qualle-augsburg.de/projekte#KippenfreieWertachstra%C3%9Fe"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kippenfreie Wertachstraße
              </a> nimmt jetzt richtig Fahrt auf. Am Wochenende haben wir in zwei Aktionen die Wertachstraße von Müll- und Kippenstummeln befreit. In der nächsten Zeit werden einige selbstgebaute Aschenbecher folgen. Dazu bald mehr. Bleib gespannt!<br></br>
        </p>
        <br></br>
        <hr />

        <p>
        <i>05.10.2023</i><br></br>
        <br></br>
        Hallo liebe Menschen!<br></br>
        Wir haben uns einige Zeit nicht gemeldet. Viel ist passiert in dem Quartiersprojekt in den letzten Wochen. Neue Menschen sind dazugekommen und der Kreis der Unterstützer*innen wächst. Wir versuchen gleichzeitig als Team zusammenzuwachsen und konkrete Projekte umzusetzen. Das haben wir im Oktober vor:<br></br>
        <br></br>
        In Form einer Müllsammelaktion werden wir am 14. Oktober in das Straßenfest einsteigen (Treffpunkt um 12 Uhr an der Oase, Ecke Mittelstr/Lindenstr).<br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/1.png" alt="Fest1" />
        </div>
        <br></br>
        <b>Straßenfest am 14. & 15. Oktober (jeweils von 12 - 20 Uhr)</b><br></br>
        Zum Abschluss der Oase veranstalten wir ein zweitägiges Straßenfest mit Flohmarkt, DJ Sets, Open Stage, Bands, Infoständen zum Thema Wohnraum, Yoga, Kinderspaß, Zirkus & Küche für Alle. Der Teil der Mittelstraße zwischen Lindenstraße und Schützenstraße wird gesperrt und wir können die Fahrbahn nutzen. Wenn du selbst beim Flohmarkt mitmachen oder einfach nur etwas mitbringen möchtest, schreib uns gerne: info@qualleaugsburg.de<br></br>
        <br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/3.png" alt="Fest3" />
        </div>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/4.png" alt="Fest4" />
        </div>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/5.png" alt="Fest5" />
        </div>
        <br></br>
        <br></br>
        <b>Wie gehts weiter mit der Qualle?</b><br></br>
        Wir werden die Oase Ende Oktober abbauen. Für die kalten Monate haben wir vor in Räumlichkeiten in Rechts der Wertach zu ziehen. Dazu bald mehr.
        Fürs Erste konzentrieren wir uns jetzt mal auf den Oktober und freuen uns riesig auf die Kippenaktion, das Quallenfest & den Foodverteiler. Die Veranstaltungen kannst du wie immer bei uns im <a
                href="https://www.qualle-augsburg.de/kalender"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kalender
              </a> nachschauen.
        Vielleicht hast Du ja Lust bei einem der Termine dabei zu sein - wir plenieren übrigens jeden Mittwoch um 18 Uhr, aktuell noch an der Oase. Komm gerne vorbei!<br></br>
        <br></br>
        Liebe Grüße,<br></br>
        Eure Qualle<br></br>
        <br></br>
        </p>
        <hr />

        <p>
        <i>15.09.2023</i><br></br>
        <br></br>
        Foodverteiler musste vorübergehend geschlossen werden.<br></br>
        <br></br>
        Bleib up-to-date, wanns weitergeht:<br></br>
        <i>Telegram:</i> <a
                href="https://t.me/futterquelle"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://t.me/futterquelle
              </a><br></br>
        <i>WhatsApp:</i> <a
                href="https://chat.whatsapp.com/JQDpdFCYgNhDjjRLbHNrM7"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://chat.whatsapp.com/JQDpdFCYgNhDjjRLbHNrM7
              </a><br></br>
        <br></br><br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/FoodverteilerSchließung.png" alt="Verteilerschließung" />
        </div>
        </p>
        <br></br>
        <hr />

        <p>
        <i>04.09.2023</i><br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Quallenmenschen.jpeg" alt="wir sind die Qualle" />
        </div>
        Wir sind die Qualle!<br></br>
        <br></br>
        Seit etwa drei Monaten steht nun die Oase in der Mittelstraße. Zeit auf das bisher erreichte zurückzuschauen:<br></br>
        Wir haben einen Ort der Begegnung geschaffen, an dem Mensch aus dem Quartier verweilen, sich treffen und vernetzen. Wir haben bereits zwei Flohmärkte und einen Veganen Brunch mit Poetry Slam organisiert, welche gut besucht wurden.<br></br>
        Die Verteilerstation "Futterquelle" wird regelmäßig mit gerettetem Essen gefüllt und gibt den Menschen aus dem Quartier die Möglichkeit sich ohne Gegenleistung zu bedienen. Etwa 300 Menschen nutzen unsere digitalen Kommunikationsstrukturen, um up-to-date zu bleiben, wann wieder etwas zur Abholung bereit steht.<br></br>
        Es gibt ein Tauschregal, was die Nachbarschaft fleißig nutzt. Was man selbst nicht mehr braucht muss nicht direkt in der Tonne landen, sondern erhält bei neuen Menschen ein zweites Leben.<br></br>
        <br></br>
        Wir werden die Oase Ende Oktober abbauen. Für die kalten Monate wollen wir einen Schritt weitergehen und sind im Moment auf aktiver Suche nach Räumlichkeiten in Rechts der Wertach. Bald wird es ein Quartierszentrum geben!<br></br>
        <br></br>
        Gleichzeitig hat sich über die vergangenen Monate ein Kernteam aktiver Menschen herauskristallisiert, was nachhaltige und soziale Projekte in unserem Quartier selbstorganisiert angeht (dazu bald mehr).<br></br>
        <i>Wir treffen uns jetzt jeden Mittwoch um 18 Uhr an der Oase.</i> In diesem Plenum werden Veranstaltungen und Aktionen geplant, die Betreuung der Oase organisiert und längerfristige Projekte geplant. Wenn auch Du Lust hast Rechts der Wertach mitzugestalten, komm vorbei!<br></br>
        </p>
        <hr />

        <p>
        <i>30.08.2023</i><br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/onboardingaugust.jpeg" alt="15.08." />
        </div>
        Du willst dich der Qualle anschließen und gemeinsam Rechts der Wertach so gestalten, dass es unseren Bedürfnissen gerecht wird? Dann komm am nächsten Mittwoch zum Treffen an der Oase!<br></br>
        In der nächsten Zeit gehen wir einige Projekte an: Müllsammel- und Anti-Kippenstummelprojekte, Fahrradständer bauen, Biodiversitätsprojekte und Küche für Alle - um nur ein paar Ideen zu nennen. Zusätzlich wollen wir die kulturellen Veranstaltungen, wie der Flohmarkt und den veganen Brunch, regelmäßig stattfinden lassen.<br></br>
        <br></br>
        Wenn Du dich für eines der Projekte interessierst, selbst eine Projektidee hast oder einfach mal reinschnuppern möchtest - komm am 30.08.23 ab 18 Uhr an die Oase in Rechts der Wertach!<br></br>
        </p>
        <hr />

        <p>
        <i>14.08.2023</i><br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/2.flohmarkt 1.jpeg" alt="15.08." />
        </div>
        Rechts der Wertach, geradeaus Richtung Slowfashion, Gemeinschaftsleben und kleine positive Veränderungen in deiner Gegend, wir, die Qualle, laden Dich ❤️lich ein zu unserem zweiten Flohmarkt.<br></br>
        Verkauf was die anderen gebrauchen könnten, lerne die Oase kennen und gestalte deine Sachen neu mit unserer Upcycling Session! Come for Clothes stay for the Change!<br></br>
        <br></br>
        <b>Freitag, 18. August<br></br>
          15 - 20 Uhr<br></br>
          Oase - Ort der Begegnung in Rechts der Wertach, Ecke Mittelstraße/Lindenstraße
        </b>
        <br></br><br></br>
        Info: Es gibt eine Upcycling Session mit Nähmaschinen und Patches (da wir auch Strom haben!), also bring mit, was du gerne wieder oder neu tragen willst. Wenn du was verkaufen magst, bring am besten eine Kleiderstange mit oder ein Tischchen, sonst haben wir auch eine Wäscheleine für alle. Wir freuen uns Riesig!<br></br>
        </p>
        <hr />

        <p>
        <i>10.08.2023</i><br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Veganer Brunch.jpeg" alt="15.08." />
        </div>
        Wir haben die beiden happenings im August noch einmal nach hinten verschoben. Nun also freuen wir uns die nächsten Termine anzukündigen:<br></br>
        <br></br>
        <b>Dienstag, der 15. August - Veganer Brunch & Poetry-Slam - 12:00 bis 17:00 Uhr</b><br></br>
        Bring' einen veganen Salat, Snacks oder Sonstiges mit. Dazu tauschen wir uns aus und lauschen poetischen Gedichten und Texten, die gerne gesellschaftskritisch sein dürfen!<br></br>
        <i>Wenn Du also selbst etwas vortragen möchtest, schreibe uns gerne eine Mail.</i><br></br>
        <br></br>
        <b>Freitag, der 18. August - 2. Flohmarkt (mit Upcycling-Workshop) - 15:00 bis 20:00 Uhr</b><br></br>
        Wir verwandeln die Ecke Mittelstraße/Lindenstraße in eine Flohmarkt-Oase mitten in der Stadt. Den ganzen Nachmittag kannst du hier stöbern, tauschen und Dinge ergattern.<br></br>
        <i>Wenn Du Lust hast selbst etwas auf Spendenbasis anzubieten, komm auf uns zu!</i><br></br>
        Neben dem Flohmarkt auf Spendenbasis wird es einige Nähmaschinen geben mit Menschen, die dir zeigen, wie Du kaputte Klamotten wieder auf Vordermensch bringst oder verschönerst. Bring also mit, was Du gestalten willst.<br></br>
        </p>
        <hr />

        <p>
        <i>29.07.2023</i><br></br>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/futterquelle1.jpeg" alt="Futterquelle" />
      </div>
        Heute eröffnen wir offiziell die neue Foodstation in Rechts der Wertach - die <b>Futterquelle</b>!<br></br>
        <br></br>
        Ab sofort könnt ihr Tag und Nacht an der Oase gerettetes Essen abholen.<br></br>
        1. Essen ist für alle da.<br></br>
        2. Nimm soviel Du brauchst<br></br>
        3. Hinterlasse die Futterquelle so, wie Du sie vorgefunden hast.<br></br>
        4. Genieß das gerettete Essen.<br></br>
        <br></br>
        Wenn Du nicht mehr verpassen willst, wenn gerettete Lebensmittel eintreffen, spring in die Gruppe:<br></br>
        ➡️WhatsApp: <a
                href="https://chat.whatsapp.com/JQDpdFCYgNhDjjRLbHNrM7"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://chat.whatsapp.com/JQDpdFCYgNhDjjRLbHNrM7
              </a><br></br>
        <br></br>
        An alle Menschen die Essen retten und es in die Futterquelle bringen: Postet bitte ein Bild in die Gruppe und beschreibt kurz, was in der Foodstation anzutreffen ist.<br></br>
        </p>
        <hr />

        <p>
        <i>28.07.2023</i><br></br>
        <br></br>
        Endlich ist es soweit. Wir, die Qualle – Quartier für Alle, haben einen eigenen Webauftritt.<br></br>
        Seit nunmehr 6 Wochen steht die Oase an der Ecke Mittelstraße/Lindenstraße in Rechts der Wertach. Als erstes Projekt haben wir einen Ort der Begegnung geschaffen, an dem sich Menschen begegnen können, sich vernetzen und austauschen, um gemeinsam neue Ideen für die Gestaltung unserer direkten Umgebung zu finden und uns an deren Umsetzung zu wagen.
        Mehr dazu <a
                href="www.qualle-augsburg.de/projekte"
                target="_blank"
                rel="noopener noreferrer"
              >
                hier
              </a>.<br></br>
        </p>
        <br></br>
      </div>
      <hr />
    </>
  );
}
