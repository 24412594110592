import { Link, To } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { NavHashLink } from "react-router-hash-link";
import { ReactNode } from "react";

export function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-xl">
        <Link className="navbar-brand" to={"/"}>
          <img
            src="img/WebsiteImage2.png"
            alt="Logo"
            style={{ height: "5em", marginLeft: "4em" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          style={{ border: "solid black" }}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="navbar-nav mx-auto mb-2 mb-lg-0">
            <NavItem name="Aktuelles" to="/" />

            <NavItemDropdown name="Wer wir sind">
              <NavDropdownLink name="Idee" to="/werwirsind#idee" />
              <NavDropdownLink name="Ueber uns" to="/werwirsind#überuns" />
              <NavDropdownLink name="Verein" to="/werwirsind#verein" />
            </NavItemDropdown>

            <NavItem name="Kalender" to="/kalender" />

            <NavItemDropdown name="Quartierszentrum">
              <NavDropdownLink
                name="Ort der Begegnung"
                to="/quartierszentrum#ortderbegegnung"
              />
              <NavDropdownLink
                name="Nutze diesen Raum"
                to="/quartierszentrum#nutzediesenraum"
              />
              <NavDropdownLink
                name="Bibliothek der Dinge"
                to={"/quartierszentrum#bibliothekderdinge"}
              />
              <NavDropdownLink
                name="Kippenauffangstation"
                to={"/quartierszentrum#kippenauffangstation"}
              />
            </NavItemDropdown>

            <NavItemDropdown name="Projekte">
              <NavDropdownHeader
                name="Aktive Projekte"
                to={"/projekte#aktiveprojekte"}
              />
              <NavDropdownLink
                name="→ Projekte rund ums Quartierszentrum"
                to={"/projekte#projektequartierszentrum"}
              />
              <NavDropdownLink
                name="→ Essensprojekte"
                to={"/projekte#essensprojekte"}
              />
              <NavDropdownLink
                name="→ Gartenprojekte"
                to={"/projekte#gartenprojekte"}
              />
              <NavDropdownHeader
                name="Etablierte Projekte"
                to={"/projekte#etablierteprojekte"}
              />
              <NavDropdownLink
                name="→ Kippenprojekt"
                to={"/projekte#kippenprojekt"}
              />
              <NavDropdownLink
                name="→ Foodverteiler"
                to={"/projekte#foodverteiler"}
              />
              <NavDropdownLink
                name="→ Tauschschrank"
                to={"/projekte#tauschschrank"}
              />
              <NavDropdownHeader
                name="Projekte in 2023"
                to={"/projekte#projekte-in-2023"}
              />
              <NavDropdownLink
                name="→ Oase"
                to={"/projekte#oase"}
              />
              <NavDropdownLink
                name="→ Quallenfest"
                to={"/projekte#quallenfest"}
              />
            </NavItemDropdown>

            <NavItem name="Presse" to="/presse" />

            <NavItemDropdown name="Kontakt">
              <NavDropdownLink
                name="Unterstuetze uns"
                to="/kontakt#unterstuetzeuns"
              />
              <NavDropdownLink name="Adresse" to="/kontakt#adresse" />
              <NavDropdownLink
                name="Befreundete Gruppen"
                to={"/kontakt#befreundetegruppen"}
              />
            </NavItemDropdown>
          </div>

          <div style={{ display: "flex" }}>
            <a
              className="nav-link"
              href="mailto:info@qualleaugsburg.de"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-envelope-fill headerIcons"></i>
            </a>
            <a
              className="nav-link"
              href="https://www.instagram.com/qualle_augsburg/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-instagram headerIcons"></i>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

/** A simple navigation menu element pointing to a subpage. */
function NavItem(prop: { name: string; to: To }) {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={prop.to}>
        <NavbarItem text={prop.name} />
      </Link>
    </li>
  );
}

/** A navigation menu element that is a dropdown of more menu items. */
function NavItemDropdown(prop: { name: string; children: ReactNode[] }) {
  return (
    <li className="nav-item dropdown">
      <span
        className="nav-link dropdown-toggle"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {prop.name}
      </span>
      <ul className="dropdown-menu" style={{ backgroundColor: "#F36976" }}>
        {prop.children}
      </ul>
    </li>
  );
}

function NavDropdownLink(prop: { name: string; to: To }) {
  return (
    <NavHashLink className="nav-link" to={prop.to}>
      <NavbarItem text={prop.name} />
    </NavHashLink>
  );
}

function NavDropdownHeader(prop: { name: string; to: To }) {
  return (
    <NavHashLink className="nav-link" to={prop.to}>
      <h2>{prop.name}</h2>
    </NavHashLink>
  );
}

export function NavbarItem(props: { text: string }): JSX.Element {
  return (
    <span
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      {props.text}
    </span>
  );
}
